import * as React from "react";
import styled from "styled-components";
import StarPointIcon from "../../images/StarPointIcon.png";
import { ScreenSizes } from "../../shared-code";

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 100%;
  }
`;

const StickerText = styled.div`
  height: 36px;
  padding: 4px 5px;
  line-height: 30px;
  gap: 4px;
  border-bottom-right-radius: 14px;
  border-top-left-radius: 8px;
  background: #f34a4a;
  box-shadow: 0.5px 2px 1px 0px rgba(0, 0, 0, 0.4);
  text-shadow: 0.5px 1px 0.5px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding-inline: 10px;
  display: 2;

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 30px;
    font-size: 18px;
    text-align: center;
  }
`;

const StarPointWrapper = styled.div<{ hasSticker: boolean }>`
  display: inline-flex;
  height: 36px;
  padding: 4px 5px;
  align-items: center;
  gap: 4px;
  border-top-left-radius: ${(props) => (props.hasSticker ? "0px" : "10px")};
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  font-size: 20px;
  color: black;
  display: 2;

  & > img {
    height: 20px;
    aspect-ratio: 1 / 1;
  }
`;

interface StarPointBadgeProps {
  points: number;
  stickerText?: string | undefined;
}

export const StarPointBadge = ({
  points,
  stickerText,
}: StarPointBadgeProps) => {
  return (
    <Wrapper>
      {stickerText != undefined ? (
        <StickerText>{stickerText}</StickerText>
      ) : null}
      {points > 0 && (
        <StarPointWrapper hasSticker={stickerText != undefined}>
          {"+" + points.toLocaleString()}
          <img src={StarPointIcon} />
        </StarPointWrapper>
      )}
    </Wrapper>
  );
};
