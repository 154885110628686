import * as React from "react";
import styled from "styled-components";
import loyaltyPointIcon from "../../images/StarPointIcon.png";
import { spawn } from "child_process";

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
`;

const Sticker = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 44px;
  width: 100%;
  background: #f34a4a;
  font-size: 18px;
  font-weight: 600;
  padding: 6px;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const Price = styled.div`
  position: absolute;
  width: 100%;
  height: 44px;
  padding: 3px 0 1px 0;
  bottom: 0%;
  background: linear-gradient(
    180deg,
    rgba(2, 31, 60, 0.5) 0%,
    rgba(2, 31, 60, 0.25) 100%,
    rgba(2, 31, 60, 0.35) 100%
  );
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

  & img {
    height: 30px;
  }
`;

const Remaining = styled.div<{ isLockable: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: ${(props) => (props.isLockable ? "69px" : "44px")};
  right: 0px;
  border-top-left-radius: 10px;
  height: 26px;
  font-size: 17px;
  font-weight: 600;
  text-align: right;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
`;

const LockedContainer = styled.div`
  position: absolute;
  bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 25px;
  padding-block: 1px;
  background: rgba(0, 0, 0, 0.8);
`;

const LockedText = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  padding-top: 1px;
  position: absolute;
  gap: 3px;
  text-shadow: 1px 1px 0 #000, -1px 1px 0 #000, -1px -1px 0 #000,
    1px -1px 0 #000;

  & img {
    height: 20px;
  }
`;

const LockedProgress = styled.div<{ progress: number }>`
  width: ${(props) => `${props.progress}%`};
  height: 23px;
  background: linear-gradient(90deg, #f93822 0%, #ff8d00 100%);
`;

type OfferFooterProps = {
  stickerText: string | null;
  amount: number;
  remaining: number | undefined;
  locked: boolean;
  spendNeeded: number | null;
  thresholdPercent?: number | undefined;
};
export const LoyaltyOfferFooter = ({
  amount,
  stickerText,
  remaining,
  locked = false,
  spendNeeded,
  thresholdPercent,
}: OfferFooterProps) => {
  const localizedCost = Intl.NumberFormat("en-US").format(amount);
  return (
    <CardFooter>
      {remaining != undefined && (
        <Remaining isLockable={locked != null}>
          {Intl.NumberFormat("en-US").format(remaining) + " REMAINING"}
        </Remaining>
      )}
      {thresholdPercent != undefined && (
        <LockedContainer>
          <LockedProgress progress={thresholdPercent} />
          <LockedText>
            {thresholdPercent == 100 ? (
              "UNLOCKED"
            ) : (
              <>
                <span>{`SPEND ${spendNeeded} MORE`}</span>
                <img src={loyaltyPointIcon} alt="" />
                <span>TO UNLOCK</span>
              </>
            )}
          </LockedText>
        </LockedContainer>
      )}
      {stickerText != undefined && (
        <Sticker>{stickerText.toUpperCase()}</Sticker>
      )}
      <Price>
        <img src={loyaltyPointIcon} alt="" />
        <span>
          {remaining == undefined || remaining > 0 ? localizedCost : "CLAIMED"}
        </span>
      </Price>
    </CardFooter>
  );
};
