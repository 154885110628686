import * as React from "react";
import styled from "styled-components";
import expiryIcon from "../../images/expiryIcon.png";
import refreshIcon from "../../images/refreshIcon.png";
import CustomTimer from "../shared/CustomTimer";

const Wrapper = styled.div`
  position: absolute;
  top: -35px;
  right: 8px;
  display: inline-flex;
  height: 35px;
  padding: 2px 16px 0px 16px;
  align-items: center;
  gap: 6px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  font-size: 18px;
  color: white;

  & > img {
    height: 20px;
    aspect-ratio: 1 / 1;
  }

  & > span {
    padding-top: 3px;
  }
`;

export const ExpirationTypes: Record<string, string> = {
  expiry: expiryIcon,
  refresh: refreshIcon,
};

interface ExpirationBadgeProps {
  expirationTime: number;
  expirationType: string;
  serverOffset: number;
}

export const ExpirationBadge = ({
  expirationTime,
  expirationType,
  serverOffset,
}: ExpirationBadgeProps) => {
  return (
    <Wrapper>
      <img src={expirationType} />
      <span>
        <CustomTimer
          expirationTime={expirationTime}
          serverOffset={serverOffset}
        />
      </span>
    </Wrapper>
  );
};
