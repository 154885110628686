import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import { StarPointBadge } from "./StarPointBadge";
import { ExpirationBadge, ExpirationTypes } from "./ExpirationBadge";
import { GoldOffer } from "../DataModels";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 215px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 215px;
  background: radial-gradient(
    66.12% 70.71% at 50% 50%,
    #fff2d1 0%,
    #dba424 100%
  );
  border-radius: 8px;
  cursor: pointer;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GoldCardImage = styled.img`
  height: 135px;
  margin-top: -20px;
`;

const GoldCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  padding-right: 10px;
  padding-top: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  text-align: right;
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
`;

export interface GoldCardProps {
  offer: GoldOffer;
  flagText: string;
  purchaseFn: () => void;
  loyaltyPoints: number;
}

export const GoldCard = (props: GoldCardProps) => {
  const divId = "gold_" + props.offer.sku;
  const goldCount = parseInt(props.offer.name.split(" ")[0]);
  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.attributes[0].values[0].value}
        serverOffset={0}
      />
      <Wrapper
        key={divId}
        id={divId}
        onClick={() => {
          props.purchaseFn();
        }}
      >
        <GoldCardImage src={props.offer.image_url} alt="props-icon" />
        <GoldCount>{goldCount.toLocaleString()}</GoldCount>
        <OfferFooter
          stickerText={props.flagText}
          currency={props.offer.price.currency}
          amount={props.offer.price.amount}
        />
      </Wrapper>
    </OuterWrapper>
  );
};
