import * as React from "react";
import styled from "styled-components";
import HamburgerOpen from "../../images/Hamburger/menu-button-open.png";
import { MetalstormIcon } from "../Icons";
import StarPointIcon from "../../images/StarPointIcon.png";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const PopOver = styled.div`
  max-width: 450px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  background: rgba(13, 23, 35, 0.7);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 11;
  position: relative;
  padding: 40px;
`;

const CloseX = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
  height: 100px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const BodyText = styled.div`
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
`;

const PurchaseButton = styled.button`
  border-radius: 8px;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background: #00ba8d;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.6);
  width: 160px;
  height: 65px;
  margin-top: 14px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;

  & img {
    height: 30px;
    filter: drop-shadow(0.5px 1px 0px rgba(0, 0, 0, 0.4));
  }

  & span {
    color: #fff;
    text-align: center;
    padding-top: 4px;
    text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);
    font-size: 30px;
    font-weight: 400;
  }
`;

const StarPointBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(0, 0, 0, 0.4);

  & p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
  }
`;

const StarPointBubble = styled.div`
  display: flex;
  height: 36px;
  padding: 4px 5px;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background: #fff;

  & span {
    color: #353535;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-top: 5px;
  }

  & img {
    height: 20px;
    filter: drop-shadow(0.5px 1px 0px rgba(0, 0, 0, 0.4));
  }
`;

const StarPointBlockText = styled.p``;

export const ConfirmationSteps: Record<string, string> = {
  hidden: "hidden",
  confirmation: "confirmation",
  deliveredFree: "deliveredFree",
  deliveredPaid: "deliveredPaid",
};

export interface ComponentProps {
  step: string;
  closeFn: () => void;
  purchaseFn?: () => void;
  itemName?: string;
  starPointsRequired?: number;
  starPointsAwarded?: number;
}

export default (props: ComponentProps) => {
  let bodyContent;

  switch (props.step) {
    case ConfirmationSteps.confirmation:
      bodyContent = (
        <>
          <Title>Confirm Purchase</Title>
          <BodyText>Purchase {props.itemName}?</BodyText>
          <PurchaseButton onClick={props.purchaseFn}>
            <ButtonText>
              <img src={StarPointIcon} alt="" />
              <span>{props.starPointsRequired}</span>
            </ButtonText>
          </PurchaseButton>
        </>
      );
      break;
    case ConfirmationSteps.deliveredFree:
      bodyContent = (
        <>
          <MetalstormIcon iconSize={40} color={"white"} />
          <Title>Purchase Complete!</Title>
          <BodyText>
            Items have been delivered to your in-game inventory.
          </BodyText>
        </>
      );
      break;
    case ConfirmationSteps.deliveredPaid:
      bodyContent = (
        <>
          <MetalstormIcon iconSize={40} color={"white"} />
          <Title>Purchase Complete!</Title>
          <BodyText>
            Items have been delivered to your in-game inventory.
          </BodyText>
          <StarPointBlock>
            <StarPointBubble>
              <span>+{props.starPointsAwarded}</span>
              <img src={StarPointIcon} alt="" />
            </StarPointBubble>
            <StarPointBlockText>
              You recieved {props.starPointsAwarded} Star Points to spend in the
              Metalstorm Web Shop!
            </StarPointBlockText>
          </StarPointBlock>
        </>
      );
      break;
    default:
      return null;
  }

  return (
    <Wrapper>
      <PopOver>
        <CloseX src={HamburgerOpen} onClick={props.closeFn} />
        {bodyContent}
      </PopOver>
    </Wrapper>
  );
};
