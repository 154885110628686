import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import bgDesktop from "../../images/SpecialOffer/backgrounds/BG-desktop-offer.png";
import bgTablet from "../../images/SpecialOffer/backgrounds/bg-tablet-offer.png";
import bgMobile from "../../images/SpecialOffer/backgrounds/BG-mobile-offer.png";
import bgNarrow from "../../images/SpecialOffer/backgrounds/BG-narrow-offer.png";
import {
  Lerp,
  S3BucketPrefix,
  ScreenSizes,
  screenWidth,
} from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import hugeCrate from "../../images/SpecialOffer/huge-crate.png";
import goldPile from "../../images/SpecialOffer/goldOffer.png";
import { DailyOrSpecialOffer } from "../DataModels";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 315px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 315px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: ${ScreenSizes.tablet}) {
    background: url(${bgTablet});
    background-size: cover;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    background: url(${bgMobile});
    background-size: cover;
    background-position: right;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    background: url(${bgNarrow});
    background-size: cover;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  padding: 50px 15px 5px 15px;
  width: 300px;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 260px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 300px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 100%;
  }
`;

const Name = styled.div`
  height: 76px;
  font-size: 36px;
  font-weight: 600;
  line-height: 105%;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  z-index: 0;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 250px;
    font-size: 34px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 340px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 180px;
  }
`;

const Awards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AwardLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 43px;
  background: rgba(255, 255, 255, 0.1);
  gap: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);

  @media (max-width: ${ScreenSizes.tablet}) {
    font-size: 16px;
  }
`;

const AwardText = styled.span`
  display: inline-flex;
  gap: 5px;
`;

const IconCell = styled.div`
  width: 43px;
  height: 43px;
  background: rgba(255, 255, 255, 0.12);

  & > img {
    height: 43px;
  }
`;

const Sticker = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #f34a4a;
  border-radius: 50%;
  height: 92px;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.6);
  font-weight: 600;
  padding-top: 10px;
  text-shadow: 0.5px 1px 0 #000;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 64px;
    padding-top: 5px;
  }
`;

const StickerMain = styled.span`
  font-size: 36px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 24px;
  }
`;

const StickerSub = styled.span`
  font-size: 22px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: calc(100% - 685px);
  width: 685px;
  background-repeat: no-repeat;
`;

const PlaneImage = styled.img`
  position: absolute;
  top: -200px;
  left: calc(100% - 500px);
  width: 685px;
  background-repeat: no-repeat;
`;

interface award {
  text: string;
  icon: string;
}

export interface SpecialOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
  onClick: () => void;
}

export default (props: SpecialOfferCardProps) => {
  const imageURL =
    props.offer.sku == "starterbundle"
      ? hugeCrate
      : props.offer.sku == "triggerable5"
      ? goldPile
      : S3BucketPrefix + "plane-bundles/" + props.offer.image_url;

  let backgroundURL = bgDesktop;

  // if (screenWidth < 501) {
  //   backgroundURL = bgNarrow;
  // }
  // if (screenWidth < 801) {
  //   backgroundURL = bgMobile;
  // }
  // if (screenWidth < 1025) {
  //   backgroundURL = bgTablet;
  // }

  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={props.offer.expires}
        expirationType={ExpirationTypes.expiry}
        serverOffset={props.offer.serverOffset}
      />
      <Wrapper onClick={props.onClick}>
        <CardBody>
          <Name>{props.offer.name}</Name>
          <Awards>
            {props.offer.awards.map((award) => (
              <AwardLine>
                <IconCell>
                  <img src={award.icon} />
                </IconCell>
                <AwardText>
                  <span>{award.amount}</span> <span>{award.text}</span>
                </AwardText>
              </AwardLine>
            ))}
          </Awards>
        </CardBody>
        {props.offer.stickerText != undefined ? (
          <Sticker>
            <StickerMain>{props.offer.stickerText.split(" ")[0]}</StickerMain>
            <StickerSub>{props.offer.stickerText.split(" ")[1]}</StickerSub>
          </Sticker>
        ) : null}
        <BackgroundImage src={backgroundURL} />
        <PlaneImage src={imageURL} />
        <OfferFooter stickerText={null} {...props.offer.price} />
      </Wrapper>
    </OuterWrapper>
  );
};
