import * as React from "react";
import styled from "styled-components";
import { ScreenSizes } from "../../shared-code";

const Wrapper = styled.div<{ h: number; w: number; fullWidth: boolean }>`
  grid-column: span ${(props) => (props.fullWidth == true ? 2 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? "100%" : `${props.w}px`)};
  height: ${(props) => `${props.h}px`};
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.5);
  font-size: 22px;
  line-height: 130%;
  font-weight: 400;

  & > p {
    max-width: 315px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    display: ${(props) => (props.fullWidth ? "flex" : "none")};
  }
`;

export default (props: {
  h: number;
  w: number;
  text: string;
  fullWidth: boolean | false;
}) => {
  return (
    <Wrapper h={props.h} w={props.w} fullWidth={props.fullWidth}>
      <p>{props.text}</p>
    </Wrapper>
  );
};
