import { Offers } from "./components/DataModels";

export const FakeStore: Offers = {
  loyaltyPointStore: {
    offers: [
      {
        offerId: "LoyaltyPointsToSoftCurrencyOffer",
        loyaltyPointCost: 50,
        pointThreshold: undefined,
        award: {
          amount: 400,
          icon: "/static/sp-silver-0460ef3f00a8721979745363c4dbedeb.png",
          text: "Silver",
        },
        stockRemaining: undefined,
        sortOrder: 0,
      },
      {
        offerId: "LoyaltyPointsToUniversalPartsOffer",
        loyaltyPointCost: 50,
        pointThreshold: undefined,
        award: {
          amount: 50,
          icon: "/static/sp-universal-parts-f76218832e699497ae6bc06b726472e7.png",
          text: "Universal Parts",
        },
        stockRemaining: undefined,
        sortOrder: 1,
      },
      {
        offerId: "LoyaltyPointsToMediumCrateOffer",
        loyaltyPointCost: 135,
        pointThreshold: 100,
        stockRemaining: 10,
        award: {
          amount: 1,
          icon: "/static/sp-medium-crate-9bb8bc0559e57944f1bae3497fe702fa.png",
          text: "Medium Crate",
        },
        sortOrder: 2,
      },
      {
        offerId: "LoyaltyPointsToAircraftCreditsOffer",
        loyaltyPointCost: 750,
        pointThreshold: 300,
        stockRemaining: 4,
        award: {
          amount: 25,
          icon: "/static/sp-aircraft-credits-26aec437f1ec21c778b5f2d2f52f10be.png",
          text: "Aircraft Credits",
        },
        sortOrder: 3,
      },
    ],
    currentPeriodExpiration:
      Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 7 * 2, //2 weeks from now
    loyaltyPointsSpent: 0,
  },
  goldOffers: [
    {
      sku: "gold_2",
      name: "30 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/760906c73d9c52949d4d132b431bdbd5.png",
      price: {
        amount: 1.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "20", value: 20 }],
        },
      ],
      content: [],
    },
    {
      sku: "gold_5",
      name: "80 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/fbe7f8c7b9b26c527af06872e3c1a9f9.png",
      price: {
        amount: 4.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "50", value: 50 }],
        },
      ],
      content: [],
    },
    {
      sku: "gold_10",
      name: "170 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/518127805d3942b290fa51b7c4ad853d.png",
      price: {
        amount: 9.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "100", value: 100 }],
        },
      ],
      content: [],
    },
    {
      sku: "gold_20",
      name: "360 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/0982ac6b7f6c98e28ca88d627ad81e61.png",
      price: {
        amount: 19.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "200", value: 200 }],
        },
      ],
      content: [],
    },
    {
      sku: "gold_50",
      name: "950 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/c17c82e202f954fb40c6cf6773d4d929.png",
      price: {
        amount: 49.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "500", value: 500 }],
        },
      ],
      content: [],
    },
    {
      sku: "gold_100",
      name: "2000 Gold",
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/cbe336e5a880c06de2a7dd192234f67a.png",
      price: {
        amount: 99.99,
        currency: "USD",
      },
      can_be_bought: true,
      attributes: [
        {
          external_id: "lp",
          values: [{ external_id: "1000", value: 1000 }],
        },
      ],
      content: [],
    },
  ],
  dailyOffers: [
    {
      awards: [{ amount: 25, icon: "", text: "Universal Parts" }],
      consumed: false,
      expires: Math.floor(Date.now() / 1000) + 60 * 60 * 12, // 12 hours from now
      image_url: "/static/universal-parts-f76218832e699497ae6bc06b726472e7.png",
      loyaltyPoints: 0,
      name: "Free Offer",
      offerType: "daily",
      price: { currency: "", amount: 0 },
      serverOffset: 3600,
      sku: "bt,Ba5TBzH-elSNT",
      stickerText: "Web Exclusive!",
      uniqueId: "",
    },
    {
      awards: [{ text: "Silver", icon: "", amount: 2400 }],
      consumed: false,
      expires: Math.floor(Date.now() / 1000) + 60 * 60 * 12, // 12 hours from now
      image_url:
        "https://cdn3.xsolla.com/img/misc/images/9b4e9f156ab7bf5cae0fb47a735fa8e5.png",
      loyaltyPoints: 30,
      name: "",
      offerType: "daily",
      price: { amount: 2.99, currency: "USD" },
      serverOffset: 3600,
      sku: "xsolla_daily_1",
      stickerText: "42% Extra",
      uniqueId: "3CljG7VI17jkNXwM",
    },
  ],
  specialOffers: [],
  serverOffset: 0,
};
