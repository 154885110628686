import * as React from "react";
import {
  getRemainingTimeInSeconds,
  getShortTimeString,
} from "../../shared-code";

interface MyProps {
  expirationTime: number;
  serverOffset: number;
}

interface MyState {
  timeDisplay: String;
  timerInterval?: NodeJS.Timeout | undefined;
}

export default class CustomTimer extends React.Component<MyProps, MyState> {
  state: MyState = {
    timeDisplay: getShortTimeString(
      getRemainingTimeInSeconds(
        this.props.expirationTime,
        this.props.serverOffset
      )
    ),
    timerInterval: undefined,
  };

  componentDidMount(): void {
    if (this.state.timerInterval == undefined) {
      const interval = setInterval(() => {
        const newShortTimeString = getShortTimeString(
          getRemainingTimeInSeconds(
            this.props.expirationTime,
            this.props.serverOffset
          )
        );
        if (newShortTimeString != this.state.timeDisplay) {
          this.setState({
            timeDisplay: newShortTimeString,
          });
        }
      }, 1000);
      this.setState({ timerInterval: interval });
    }
  }

  componentWillUnmount(): void {
    if (this.state.timerInterval != undefined) {
      clearInterval(this.state.timerInterval);
    }
  }

  render() {
    return <>{this.state.timeDisplay}</>;
  }
}
