import * as React from "react";
import styled from "styled-components";

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
`;

const Sticker = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 42px;
  /* height: 26px; */
  width: 100%;
  background: #f34a4a;
  font-size: 18px;
  font-weight: 600;
  padding: 6px;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const Price = styled.div`
  position: absolute;
  width: 100%;
  height: 42px;
  bottom: 0%;
  background: linear-gradient(
    180deg,
    rgba(2, 31, 60, 0.5) 0%,
    rgba(2, 31, 60, 0.25) 100%,
    rgba(2, 31, 60, 0.35) 100%
  );
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 47px;
  color: #ffffff;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
`;

type OfferFooterProps = {
  stickerText: string | null;
  currency: string;
  amount: number;
};

export const OfferFooter = ({
  stickerText,
  currency,
  amount,
}: OfferFooterProps) => {
  const localizedCost = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount);
  return (
    <CardFooter>
      {stickerText && <Sticker>{stickerText.toUpperCase()}</Sticker>}
      <Price>{localizedCost}</Price>
    </CardFooter>
  );
};
